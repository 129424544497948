<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="8" class='p-0'
      >
        <div class="w-100 d-lg-flex ">
          <b-img
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('Welcome to Taktikat!') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Please sign-in to your account and start the adventure') }}
          </b-card-text>

          <!-- form -->
          <div v-if="!verify">
            <validation-observer
              ref="loginForm"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  :label="$t('labels.email')"
                  label-for="login-email"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('labels.email')"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      :placeholder="$t('labels.email')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t('labels.password') }}</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>{{ $t('Forgot Password') }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('labels.password')"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('labels.password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!--                &lt;!&ndash; checkbox &ndash;&gt;-->
                <!--                <b-form-group>-->
                <!--                  <b-form-checkbox-->
                <!--                    id="remember-me"-->
                <!--                    v-model="status"-->
                <!--                    name="checkbox-1"-->
                <!--                  >-->
                <!--                    Remember Me-->
                <!--                  </b-form-checkbox>-->
                <!--                </b-form-group>-->

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading"
                >

                  {{ $t('Sign in') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>

          <div v-if="verify">
            <validation-observer
              ref="authForm"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="verifyForm"
              >
                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="two_factor_code">{{ $t('Verification Code') }}</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Verification Code')"
                    vid="two_factor_code"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="two_factor_code"
                        v-model="form.two_factor_code"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        type="text"
                        name="two_factor_code"
                        :placeholder="$t('Verification Code')"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('Verify') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>

          <!-- form -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      verify: false,
      loading: false,
      form: {
        two_factor_code: '',
      },
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/LightTheme.png')
        return this.sideImg
      // }
      // return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.loading = true
            useJwt.login({
              email: this.userEmail,
              password: this.password,
            })
              .then(response => {
                useJwt.setToken(response.data.access_token)
                useJwt.setRefreshToken(response.data.access_token)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: response.data.message,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
                this.loading = false
                this.verify = true
              })
              .catch(error => {
                this.$refs.loginForm.setErrors(error.response.data.errors)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-start',
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
                this.loading = false
              })
          }
        })
    },
    verifyForm() {
      this.$refs.authForm.validate()
        .then(success => {
          if (success) {
            useJwt.verification({
              two_factor_code: this.form.two_factor_code,
            })
              .then(response => {
                let {
                  user,
                  // eslint-disable-next-line prefer-const
                  permissions,
                } = response.data.data
                user = {
                  ...user,
                  ability: [{
                    action: 'manage',
                    subject: 'all',
                  }],
                }
                localStorage.setItem('userData', JSON.stringify(user))
                localStorage.setItem('permissions', JSON.stringify(permissions))

                this.$ability.update(user.ability)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getHomeRouteForLoggedInUser('admin'))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `${this.$t('Welcome')} ${user.name || user.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: this.$t('You have successfully logged in. Now you can start to explore the system'),
                      },
                    })
                  })
              })
              .catch(error => {
                this.$refs.authForm.setErrors(error.response.data.errors)
                const errors = error.response.data.errors ?? {}
                const first = Object.keys(errors)[0]
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-start',
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: errors[first][0],
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

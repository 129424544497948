var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"p-0",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex "},[_c('b-img',{attrs:{"src":_vm.imgUrl,"alt":"Login"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('Welcome to Taktikat!'))+" 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Please sign-in to your account and start the adventure'))+" ")]),(!_vm.verify)?_c('div',[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.email'),"label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('labels.email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false:null,"name":"login-email","placeholder":_vm.$t('labels.email')},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.$t('labels.password')))]),_c('b-link',{attrs:{"to":{name:'auth-forgot-password'}}},[_c('small',[_vm._v(_vm._s(_vm.$t('Forgot Password')))])])],1),_c('validation-provider',{attrs:{"name":_vm.$t('labels.password'),"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":_vm.$t('labels.password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Sign in'))+" ")])],1)]}}],null,false,1696155292)})],1):_vm._e(),(_vm.verify)?_c('div',[_c('validation-observer',{ref:"authForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.verifyForm($event)}}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"two_factor_code"}},[_vm._v(_vm._s(_vm.$t('Verification Code')))])]),_c('validation-provider',{attrs:{"name":_vm.$t('Verification Code'),"vid":"two_factor_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"two_factor_code","state":errors.length > 0 ? false:null,"type":"text","name":"two_factor_code","placeholder":_vm.$t('Verification Code')},model:{value:(_vm.form.two_factor_code),callback:function ($$v) {_vm.$set(_vm.form, "two_factor_code", $$v)},expression:"form.two_factor_code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('Verify'))+" ")])],1)]}}],null,false,1662797078)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }